.blockWrapper {
    height: 100vh;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.left {
      justify-content: flex-start;
    }

    .block {
      top: 50%;
      left: var(--grid-edge);
      background: linear-gradient(110.98deg,hsla(0,0%,100%,.05) 1.46%,hsla(0,0%,100%,.1) 94.42%);
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.141), 0 2.89208px 69.4099px -2.89208px rgba(255, 255, 255, 0.108);
    border-radius: 12px;
    padding: 20px 30px;
    max-width: 40vw;

      .title {
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
        color: white;
        line-height: 0.9;
        letter-spacing: -1px;
        margin-bottom: 10px;
        margin-top: 20px;
        text-transform: uppercase;

        .line {
          position: relative;
          display: inline-block;
          // opacity: 0;
          opacity: 1;
          // transform: rotate3d(1,.15,0,-90deg) translate3d(0,10px,0);
          transform-style: preserve-3d;
          transform-origin: 0 100%;

          &.active {
            transform: translate(0px, 0px);
            // opacity: 1;
            visibility: inherit;
          }
        }
      }

      .text {
        margin-top: 15px;
        font-size: 0.9rem;
        line-height: 1.4;
        color: white;
      }
    }
  }

  @media screen and (max-width: 768px) {

    .block {
      max-width:inherit !important;
    }

    .title {
      font-size: 2rem !important;
    }
    .text {
      font-size: 1rem !important;
    }

  }
.loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.container {
    box-sizing: border-box;
}

.wrapper {
    position: relative;
    padding: 0 20px;
}

.no-padding {
    padding: 0 !important;
}


@media only screen and (min-width: 1024px) {
    .wrapper {
        padding: 0 50px;
    }
}
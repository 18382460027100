.roadmap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  visibility: visible;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.5,0.1,0.1,1);
  
  &.active {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0.5,0.1,0.1,1);
  }
  
  &.onTransition {
    .wrapper {
      overflow-y: hidden;
    }
  }

  &.closed {
    display: none;
  }

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;

    .container {
      width: fit-content;
      overflow-x: hidden;
      overflow-y: hidden;

      .inner {
        position: relative;
        height: auto;
        width: 100%;
        padding: 40px;
        padding-right: 80px;
        padding-bottom: 0;
        z-index: 0;
        background-color: #1e1e1e;
        max-width: calc(
          (min(100vw, calc(100vw - 100px)) - 220px) * 0.75 + 160px
        );
        box-sizing: border-box;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 0;
          margin-bottom: 45px;
          .title {
            color: white;
            font-family: 'AvenirBold';
            text-transform: uppercase;
            font-size: 1.2rem;
            letter-spacing: -1px;
          }

          .close {
            .outer {
              width: 30px;
              cursor: pointer;
            }

            .inner {
              width: inherit;
              padding: 0;
              text-align: center;
            }

            label {
              font-size: 0.8em;
              line-height: 4em;
              text-transform: uppercase;
              color: #fff;
              transition: all 0.3s ease-in;
              opacity: 0;
              cursor: pointer;
            }

            .inner:before,
            .inner:after {
              position: absolute;
              content: '';
              height: 2px;
              width: inherit;
              background: #c79d54;
              left: 0;
              transition: all 0.3s ease-in;
            }

            .inner:before {
              top: 50%;
              transform: rotate(45deg);
            }

            .inner:after {
              bottom: 50%;
              transform: rotate(-45deg);
            }

            .outer:hover label {
              opacity: 1;
            }

            .outer:hover .inner:before,
            .outer:hover .inner:after {
              transform: rotate(0);
            }

            .outer:hover .inner:before {
              top: 0;
            }

            .outer:hover .inner:after {
              bottom: 0;
            }
          }
        }

        .content {
          position: relative;
          box-sizing: border-box;

          .timeLine::before {
            top: 35px;
          }

          .card {
            display: flex;
            flex-direction: column;
            min-height: 230px;
            // width: 600px;
            // background-color: #8b641f87;
            border-radius: 10px;
            box-shadow: -1rem 0 3rem #000;
            padding: 40px;
            padding-top: 20px;
            transition: 0.4s ease-out;
            position: relative;
            left: 0px;
          }

          .card:hover {
            transform: translateY(-20px);
            transition: 0.4s ease-out;
          }

          .card:hover ~ .card {
            position: relative;
            left: 50px;
            transition: 0.4s ease-out;
          }

          .card:hover ~ .date {
            transform: translateY(-20px);
            transition: 0.4s ease-out;
          }

          .title {
            color: white;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: -1px;
          }

          .subtitle {
            margin: 0;
          }

          .icon {
            background: #a98547 !important;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .description {
            color: rgba(255, 255, 255, 0.5);
          }

          .date {
            font-family: AvenirBold;
            transition: 0.4s ease-out;
            text-transform: uppercase;
            color: #c79d54;
          }

          .iconImg {
            width: 30px;
            margin-left: 5px;
            margin-top: 2px;
            border-radius: 30px;
          }

          .bar {
            position: absolute;
            // top: 100px;
            left: 40px;
            height: 5px;
            width: 350px;
          }

          .emptybar {
            background-color: #2e3033;
            width: 100%;
            height: 100%;
          }

          .filledbar {
            position: absolute;
            top: 0px;
            z-index: 3;
            width: 0px;
            height: 100%;
            // background: linear-gradient(
            //   90deg,
            //   #000000 0%,
            //   #827b6c 65%,
            //   #604a0c 100%
            // );
            background: linear-gradient(90deg, #000000 0%, #c79d54 100%);
            transition: 0.6s ease-out;
          }

          .card:hover .filledbar {
            width: 320px;
            transition: 0.6s ease-out;
          }
        }
      }
    }
  }

  .back {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .container {
      width: 100% !important;
      .inner {
        padding: 20px !important;
        width: 100% !important;
        max-width: 100% !important;
        .header {
          padding: 0 20px !important;
          margin-bottom: 0 !important;
          .title {
            font-size: 1.2rem;
          }
        }

        .content {
          .title {
            font-size: 1.3rem !important;
            margin-bottom: 10px;
          }
          .card {
            min-height: 200px;
            padding: 20px !important;
            font-size: 14px !important;

          }

          .card:hover .filledbar {
            width: 40vw !important;
          }

          .bar {
            left: 20px !important;
            width: 50vw !important;
          }

          
        }
      }
    }
  }
}


.footer {
  color: white;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bolder;

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1.4;
    color: white;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .socialIcons {
      grid-column: 11/13;
      margin-right: 0;

      .iconList {
        display: inline-flex;
        list-style: none;
        padding-left: 0px;
        margin-left: 20px;

        .item {
          margin-right: 1.5rem;

          .link {
            background-color: #ffd8940d;
            border: 1px solid rgba(199, 157, 84, 0.3);
            transition: background-color 0.25s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 8px;
            border-width: 2px;

            .icon {
              width: 25px;
              height: 25px;
              fill: rgba(199, 157, 84, 1.3);
            }
          }
        }
      }
    }
  }

  .solidLine {
    width: 25vw;
    background-color: #b3a291;
    height: 100%;
    margin: 0 10px;
  }

  .hrLine {
    margin: 10px 0;
    color: #818181;
    font-size: 0;
    height: 5px;
    background-color: #b3a291;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    font-family: 'AvenirBold';
    color: #b3a291;
    margin-bottom: 10px;

    div:first-of-type {
      margin-right: 2rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    .info {
        flex-direction: row;
      .title {
          flex-direction: row;
      }
    }
    .copyright {
      font-size: 1.4rem !important;
    }
  }
}

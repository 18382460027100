@font-face {
  font-family: 'AvenirBold';
  src: local('AvenirNextCyr-Bold'),
       url('./fonts/AvenirNextCyr-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'AvenirRegular';
  src: local('AvenirNextCyrMedium'),
       url('./fonts/AvenirNextCyrMedium.ttf') format('truetype');
}


body {
  margin: 0;
  padding: 1px;
  min-height: 100vh;
  height: -webkit-fill-available;
  background-color: #180206;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

h1,h2 {
  font-family: AvenirBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #c79d54;
}

span, div {
  font-family: AvenirRegular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#v0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover !important;
  height: 100%;
}

#v0 video {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

#set-height {
  display: block;
}

section {
  z-index: 1;
  position: relative;
}

.content {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  top: 33.3%;
}

h1 {
  font-size: 3em;
  background: #fff;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0;
}
p { font-size: 1.5em; }

section:nth-of-type(1) {
  height: 1200px;
}
section:nth-of-type(2) {
  height: 1200px;
}
section:nth-of-type(3) {
  height: 1200px;
}
section:nth-of-type(4) {
  height: 1000px;
}
section:nth-of-type(5) {
  height: 4000px;
}

.entered {
-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.no-scroll {
  overflow-x: hidden;
  overflow: hidden;
}

/* ----------------------------------------------
* Generated by Animista on 2018-7-4 16:24:12
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation text-focus-in
* ----------------------------------------
*/
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


* html .clearfix {
  height: 1%;
}
/*--------------------------------------------------------------
	10. Social
--------------------------------------------------------------*/
.basr-social-share {
  position:fixed;
  top:3em;
  left:0;
  right:0;
  width: 25%;
  display: block;
  text-align:center;
  padding:0 auto;
  margin:5em auto;
}

.social ul {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
.social ul li {
  border: 5px solid #512e12;
  border-radius: 50%;
  float: left;
  position: relative;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 480px) {
  .social ul li {
    border-width: 4px;
    width: 45px;
    height: 45px;
  }
}
.social ul li:not(:last-child) {
  margin-right: 10px;
}
.social ul li:before {
  content: '';
  border-radius: 50%;
  -webkit-shadow: inset 0 0px 3px rgba(149, 90, 42, 0.28), 0px 0px 0px 2px rgba(149, 90, 42, 0.28);
  -ms-box-shadow: inset 0 0px 3px rgba(149, 90, 42, 0.28), 0px 0px 0px 2px rgba(149, 90, 42, 0.28);
  box-shadow: inset 0 0px 3px rgba(149, 90, 42, 0.28), 0px 0px 0px 2px rgba(149, 90, 42, 0.28);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  opacity: 0.35;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 480px) {
  .social ul li:before {
    width: 43px;
    height: 43px;
  }
}
.social ul li:hover {
  border-color: #a33629;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.social ul li:hover:before {
  -webkit-shadow: inset 0 0px 3px rgba(163, 54, 41, 0.28), 0px 0px 0px 2px rgba(163, 54, 41, 0.28);
  -ms-box-shadow: inset 0 0px 3px rgba(163, 54, 41, 0.28), 0px 0px 0px 2px rgba(163, 54, 41, 0.28);
  box-shadow: inset 0 0px 3px rgba(163, 54, 41, 0.28), 0px 0px 0px 2px rgba(163, 54, 41, 0.28);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.social ul li:hover i {
  color: #d66557;
  -webkit-stroke-width: 5.3px;
  -webkit-stroke-color: #a33629;
  -webkit-fill-color: #a33629;
  text-shadow: 1px 0px 20px #a33629;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.social ul li a {
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
@media only screen and (max-width: 480px) {
  .social ul li a {
    background-size: cover;
    top: -5px;
    left: -5px;
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.social ul li a:before {
  content: '';
  background-image: url('https://img.picload.org/image/dcocwcga/border-social-hover.png');
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 46px;
  height: 45px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 480px) {
  .social ul li a:before {
    background-size: cover;
    width: 30px;
    height: 30px;
  }
}
.social ul li a:after {
  content: '';
  background-image: url('https://img.picload.org/image/dcocwcgd/border-social.png');
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 46px;
  height: 45px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 480px) {
  .social ul li a:after {
    background-size: cover;
    width: 30px;
    height: 30px;
  }
}
.social ul li a:hover:before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(360deg);
  -ms-transform: translate(-50%, -50%) rotate(360deg);
  transform: translate(-50%, -50%) rotate(360deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social ul li a:hover:after {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) rotate(360deg);
  -ms-transform: translate(-50%, -50%) rotate(360deg);
  transform: translate(-50%, -50%) rotate(360deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social ul li a i {
  color: #ffead3;
  font-size: 23px;
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-stroke-width: 5.3px;
  -webkit-stroke-color: #ffead3;
  -webkit-fill-color: #ffead3;
  text-shadow: 1px 0px 20px #ffead3;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 480px) {
  .social ul li a i {
    font-size: 16px;
  }
}

.basr-social-share.social li:hover a {
  color: #898989;
}
.basr-social-share.social li a {
  border-width: 0;
  color: #cccccc;
}
.basr-social-share.social li a:hover {
  color: #898989;
}
.basr-social-share.social li a span {
  display: none;
}

#set-height {
  display: block;
  height: 10500px;
}
#v0 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
p font-family helvetica {
  font-size: 24px;
}

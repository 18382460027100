.player-wrapper {
  opacity: 1;

  &.closed {
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
  }
}

.right {
  width: fit-content;
  position: absolute;
  right: 10%;
  bottom: 5%;
}

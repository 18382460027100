@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}

.button {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: inherit;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  .buttoncontainer2 {
    position: relative;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    font-family: AvenirBold, sans-serif;
    font-weight: 300;
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 8px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      border: none;
      outline: none;
      padding: 0 20px;
      width: 100%;
      height: 100%;
      font-family: AvenirBold, sans-serif;
      font-size: 0.8rem;
      letter-spacing: 1px;
      font-weight: bold;
      border-radius: 30px;

      @include button(
        rgba(0, 0, 0, 0.25),
        'https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png',
        3000%,
        100%,
        none,
        #fff
      );
      cursor: pointer;
      -webkit-animation: ani2 0.7s steps(29) forwards;
      animation: ani2 0.7s steps(29) forwards;

      &:hover {
        -webkit-animation: ani 0.7s steps(29) forwards;
        animation: ani 0.7s steps(29) forwards;
      }
    }
  }

  .mas {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #000;
    border-radius: 30px;
    text-align: center;
    width: 100%;
    font-family: AvenirBold, sans-serif;
    position: absolute;
    font-size: 0.8rem;
    margin-top: 10px;
    overflow: hidden;
    font-weight: bold;
    background-color: #c79d54;
    height: 50px;
    margin-top: 0;
  }

  .label {
    font-weight: bold;
    color: white;
    font-size: 0.8rem;
    line-height: 1;
    text-transform: uppercase;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: fill 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-left: 10px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    background-color: #c79d54;
    border: none;
    outline: none;
  }
  &:hover {
    span {
      color: white;
    }
    .icon {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  &.ghost {
    .mas {
      background-color: transparent;
    }

    &:hover {
      color: white;
      .icon {
        background-color: #c79d54;
      }
    }
  }

  &.solid {
    button {
      background-color: #000;
      color: #fff;
      animation: none;
      cursor: pointer;
      &:hover {
        animation: none;

        .icon {
          background-color: #c79d54;
          transform: scale(1.2);
          transition: all 0.4s;
        }
      }
      .icon {
        transition: all 0.4s;
      }
    }

    .mas {
      display: none;
    }
  }

  @-webkit-keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }

    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }

  @keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }

    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }

  @-webkit-keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }

    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }

  @keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }

    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .button {
    .buttoncontainer2 {
      button {
        padding: 0;
        width: 50px;
      }
      .text {
        display: none;
      }

      .icon {
        margin-left: 0;
      }
    }
  }
}

.s {
  .buttoncontainer2 {
    height: auto;
    .view,
    .mas {
      height: 30px !important;
      font-size: 7px !important;

      .text {
        display: initial;
      }

      .icon {
        width: 20px;
        height: 20px;
        padding: 3px;
        margin-left: 5px;
      }
    }
    button {
      width: auto;
      padding: 10px 20px;
    }
  }
}
.header {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  left: 0;
  padding: 0 20px;
  padding-top: 40px;
  box-sizing: border-box;

  .left {
    font-family: AvenirBold;
    font-size: 2rem;
    line-height: 1;
    color: #c79d54;
    position: relative;
    opacity: 0;
    padding: 5px 10px;
    outline: 2px solid;
    border-radius: 5px;

    &.active {
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 2s;
      opacity: 1;
    }
    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
        top: 30px;
      }
      100% {
        opacity: 1;
        top: 10px;
      }
    }

  }
}


@media screen and (min-width: 1024px) {
  .header {
    padding: 0 50px;
    padding-top: 40px;
  }
}

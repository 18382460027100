.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 40px;
    height: 46px;
    width: 100%;
    margin-bottom: 30px;
    z-index: 6;
    transition: opacity .3s cubic-bezier(.165,.84,.44,1);
    // padding: 0 50px;
    // box-sizing: border-box;

    .left {
        flex-shrink: 0;
        z-index: 1;

        .icon {
            width: 15px;
        }
    }

    .right {
        // flex: 1;
        display: flex;
        z-index: 1;

        .playBtn {
            width: 20px;
        }
    }
}

@media screen and (min-width: 1024px) {
    // .toolbar {
    //     margin-bottom: 60px;
    // }
}
.content {
  .blocks {
    // margin-top: 100vh;

    .empty {
      min-height: 100vh;
      height: -webkit-fill-available;
      z-index: 4;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .section07 a {
      padding-bottom: 120px;
      position: relative;
    }
    .section07 a span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 17px;
      height: 17px;
      margin-left: -12px;
      border-left: 2px solid #c79d54;
      border-bottom: 2px solid #c79d54;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb07 2s infinite;
      animation: sdb07 2s infinite;
      opacity: 0;
      box-sizing: border-box;
    }
    .section07 a span:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .section07 a span:nth-of-type(2) {
      top: 16px;
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }
    .section07 a span:nth-of-type(3) {
      top: 32px;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    @-webkit-keyframes sdb07 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes sdb07 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.container {
  width: 100%;
  opacity: 1;


  &.closing {
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .player-wrapper {
    position: relative;
    // width: 100vw;
    background: black;
    // height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
  }

  .react-player {
    // position: absolute;
    // top: 0;
    // left: 0;
    z-index: 7;
    width: 80% !important;
    height: 80% !important;
  }
}

.close {
  position: absolute;
  top: 5vh;
  right: 5vw;
  z-index: 8;
  .outer {
    width: 30px;
    cursor: pointer;
  }

  .inner {
    width: inherit;
    padding: 0;
    text-align: center;
  }

  label {
    font-size: 0.8em;
    line-height: 4em;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.3s ease-in;
    opacity: 0;
    cursor: pointer;
  }

  .inner:before,
  .inner:after {
    position: absolute;
    content: '';
    height: 2px;
    width: inherit;
    background: #c79d54;
    left: 0;
    transition: all 0.3s ease-in;
  }

  .inner:before {
    top: 50%;
    transform: rotate(45deg);
  }

  .inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }

  .outer:hover label {
    opacity: 1;
  }

  .outer:hover .inner:before,
  .outer:hover .inner:after {
    transform: rotate(0);
  }

  .outer:hover .inner:before {
    top: 0;
  }

  .outer:hover .inner:after {
    bottom: 0;
  }
}
